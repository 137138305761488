"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deferAction = deferAction;
exports.inlineProp = void 0;

function deferAction(action) {
  // Hidding setImmediate from Webpack to avoid inserting polyfill
  var _window = window,
      setImmediate = _window.setImmediate;

  if (typeof setImmediate !== 'undefined') {
    setImmediate(action);
  } else {
    setTimeout(action, 1);
  }
}

var inlineProp = function inlineProp(name, value) {
  var obj = {};
  obj[name] = value;
  return obj;
};

exports.inlineProp = inlineProp;