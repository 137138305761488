"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function FadeIn(props) {
    var _a = react_1.useState(0), maxIsVisible = _a[0], setMaxIsVisible = _a[1];
    var transitionDuration = props.transitionDuration || 400;
    var delay = props.delay || 50;
    var WrapperTag = props.wrapperTag || "div";
    var ChildTag = props.childTag || "div";
    var visible = typeof props.visible === "undefined" ? true : props.visible;
    react_1.useEffect(function () {
        var count = react_1.default.Children.count(props.children);
        if (!visible) {
            // Animate all children out
            count = 0;
        }
        if (count == maxIsVisible) {
            // We're done updating maxVisible, notify when animation is done
            var timeout_1 = setTimeout(function () {
                if (props.onComplete)
                    props.onComplete();
            }, transitionDuration);
            return function () { return clearTimeout(timeout_1); };
        }
        // Move maxIsVisible toward count
        var increment = count > maxIsVisible ? 1 : -1;
        var timeout = setTimeout(function () {
            setMaxIsVisible(maxIsVisible + increment);
        }, delay);
        return function () { return clearTimeout(timeout); };
    }, [
        react_1.default.Children.count(props.children),
        delay,
        maxIsVisible,
        visible,
        transitionDuration,
    ]);
    return (react_1.default.createElement(WrapperTag, { className: props.className }, react_1.default.Children.map(props.children, function (child, i) {
        return (react_1.default.createElement(ChildTag, { className: props.childClassName, style: {
                transition: "opacity " + transitionDuration + "ms, transform " + transitionDuration + "ms",
                transform: maxIsVisible > i ? "none" : "translateY(20px)",
                opacity: maxIsVisible > i ? 1 : 0,
            } }, child));
    })));
}
exports.default = FadeIn;
