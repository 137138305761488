"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var env_1 = require("./env");
var cache = new WeakMap();
function useSidecar(importer, effect) {
    var options = effect && effect.options || {};
    if (env_1.env.isNode && !options.ssr) {
        return [null, null];
    }
    var couldUseCache = env_1.env.forceCache || (env_1.env.isNode && !!options.ssr) || (!options.async);
    var _a = react_1.useState(couldUseCache ? function () { return cache.get(importer); } : undefined), Car = _a[0], setCar = _a[1];
    var _b = react_1.useState(null), error = _b[0], setError = _b[1];
    react_1.useEffect(function () {
        if (!Car) {
            importer()
                .then(function (car) {
                var resolved = effect ? effect.read() : (car.default || car);
                if (!resolved) {
                    console.error('Sidecar error: with importer', importer);
                    var error_1;
                    if (effect) {
                        console.error('Sidecar error: with medium', effect);
                        error_1 = new Error('Sidecar medium was not found');
                    }
                    else {
                        error_1 = new Error('Sidecar was not found in exports');
                    }
                    setError(function () { return error_1; });
                    throw error_1;
                }
                cache.set(importer, resolved);
                setCar(function () { return resolved; });
            }, function (e) { return setError(function () { return e; }); });
        }
    }, []);
    return [Car, error];
}
exports.useSidecar = useSidecar;
;
